<template>
	<div>
		<div class="company">
			<div class="company-content">
				<div class="company-header">
					<div class="company-card">

						<div class="company-logo">
							<el-image :src="setcompanylogo(companyinfo.coM_LOGO)" fit="cover"
								:preview-src-list="[setcompanylogo(companyinfo.coM_LOGO)]"></el-image>
						</div>
						<div class="company-cell">
							<div class="company-name">{{ companyinfo.coM_NAME }}</div>
							<div class="company_profile">
								<span>
									<i class="el-icon-menu" style="color:#898989"></i>
									{{companyinfo.hangye}}</span>
								<span>
									<i class="el-icon-price-tag"></i>
									{{companyinfo.leixing}}</span>
								<span>
									<i class="el-icon-user"></i>
									{{ companyinfo.guimo }}</span>
							</div>

							<div class="record">
								<div class="position2">
									<div style="color: #898989;margin-right: 15px;">在招职位</div>
									<div>{{total}}个</div>
								</div>
								<div class="position1">
									<div style="color: #898989;margin-right: 15px;">企业最近登录</div>
									<div>{{companyinfo.date}}</div>
								</div>
							</div>
						</div>

						<div style="height: 120px;">
							<div>
								<cu-button :height="40" :fontSize="18" :width="160" @click.native="favcomp()">
									{{ iscss ? "+ 关注" : "已关注" }}
								</cu-button>
							</div>
						</div>
					</div>

					<div class="company-tab">
						<div class="tab">公司主页</div>
						<a class="tab1" href="#movePosition">在招职位</a>
					</div>
					<div class="center">
						<div class="homepage">
							<div class="company-detail">
								<div class="introduce-item">
									<div class="introduce-h2">公司简介</div>
									<div class="introduce-text" v-html="companyinfo.coM_INTRODUCT"></div>
								</div>
							</div>
							<!-- <div class="company-detail">
								<div class="introduce-item">
									<div class="introduce-h2">公司环境</div>
									<div class="cardBanner">
										<ul>
											<li v-for="(item, index) in cardData" :key="index">
												<a href="#">
													<img :src="item.src" alt="">
												</a>
											</li>
											<div class="arrow-left" @click="toggleFun(-1)">&lt;</div>
											<div class="arrow-right" @click="toggleFun(1)">&gt;</div>
										</ul>
									</div>
								</div>
							</div> -->
							<div class="company-body" id="movePosition">
								<div class="position-tab-title">
									<div class="text-title">在招职位</div>
									<div class="zhaizhaozw"><span style="color:#0096FF ">{{total}}</span>个在招职位</div>
								</div>
								<div class="job-cell">
									<div @click="
              !zphid
                ? goto('/jobdetail', { id: item.oF_ID })
                : goto('/jobfairPostdetail', {
                    id: item.id,
                    zphid: zphid,
                    cid: id,
                  })
            " class="position-item" v-for="(item, i) in postdata" :key="i" @mouseenter="enter(i)">
										<div class="left">
											<div class="item-list">
												<div class="post-name">
													<div class="compName">
														{{item.oF_POSI_NAME}}
													</div>
													<div class="salary">
														{{getsalary(item)}}
													</div>
												</div>
											</div>
											<div class="item-list">
												<div class="post-salary">工作地区：{{ item.oF_WORKAREAName }}</div>
												<div
													style="width:1px;height:14px;background:#898989;margin-right: 16px;">
												</div>
												<div class="post-salary">招聘人数：{{ item.oF_INVITE_NUM }}人</div>
												<div
													style="width:1px;height:14px;background:#898989;margin-right: 16px;">
												</div>
												<div class="post-salary">
													工作经验：{{ item.oF_GZJY }}{{item.oF_GZJY=='不限'?"":"年"}}
												</div>
												<div
													style="width:1px;height:14px;background:#898989;margin-right: 16px;">
												</div>
												<div class="post-salary">
													学历：{{item.oF_EDU_REQ}}
												</div>
											</div>
										</div>
										<div class="right">
											<div class="gx">
												{{item.date}}
											</div>
										</div>
									</div>
									<div style="text-align: center; margin-top: 10px">
										<el-pagination hide-on-single-page @size-change="handleSizeChange"
											@current-change="handleCurrentChange" :current-page="PageNumber"
											:page-sizes="[10, 20, 30, 40]" :page-size="PageSize"
											layout="total, sizes, prev, pager, next, jumper" :total="total">
										</el-pagination>
									</div>
								</div>
							</div>
						</div>
						<div class="recent">
							<div class="recentBrowsing">
								<div class="title">
									<span>基本信息</span>
								</div>
								<div class="occupation">
									<div class="text">
										<i class="el-icon-mobile-phone"
											style="color:#898989;font-size: 18px;padding:0 15px ;"></i>
										{{companyinfo.coM_PHONE}}
										{{companyinfo.coM_MPHONE}}
									</div>
									<div class="text">
										<i class="el-icon-user"
											style="color:#898989;font-size: 18px;padding:0 15px ;"></i>
										{{ companyinfo.coM_LINKMAN }}
									</div>
									<div class="text">
										<i class="el-icon-location-outline"
											style="color:#898989;font-size: 18px;padding:0 15px ;"></i>
										{{companyinfo.coM_ADDRESS}}
									</div>
									<div class="text">
										<i class="el-icon-message"
											style="color:#898989;font-size: 18px;padding:0 15px ;"></i>
										{{ companyinfo.coM_EMAIL }}
									</div>
								</div>
								<!-- <div class="title">
									<span>企业福利</span>
								</div>
								<div style="padding-bottom:15px">
									<el-tag
										style=" margin-right: 10px;margin-top:18px;margin-left:10px;height: 24px; line-height: 24px; padding:0 5px"
										v-for="item in items" :key="item.label" :type="item.type" effect="dark">
										{{ item.label }}
									</el-tag>
								</div> -->
								<div class="title">
									<span>公司位置</span>
								</div>
								<div style="padding:15px">
									<maps ref="maps" :mapheight="'300px'" :keyword="companyinfo.coM_ADDRESS"></maps>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
	import {
		Getcompanydetail,
		Getcompanyposilist,
		jobfaircbycompanyid,
		AddCall,
		CancelCall
	} from "@/api/home";
	import {
		onlineposicompanylist
	} from "@/api/company";
	import {
		isnull
	} from "@/assets/js/index";
	import maps from "@/components/map";
	import global from "@/assets/js/globalconfig";
	import utils from "@/assets/js/utils";
	import CuButton from '../../components/CuButton.vue';
	export default {
		components: {
			maps,
			CuButton
		},
		data() {
			return {
				form: {
					searchKey: "",
					region: "",
					industry: "",
					field: "",
					field1: "", //职位类型
					field2: "", //公司行业
					field3: "", //工作经验
					field4: "", //学历要求
					field5: "", //薪资要求
					field6: "", //融资阶段
					field7: "", //公司规模
					order: "", //排序
					pageNumber: 1,
					pageSize: 10,
				},
				total: 0,
				cur: 0,
				items: [{
						type: '',
						label: '医疗保险'
					},
					{
						type: '',
						label: '失业保险'
					},
					{
						type: '',
						label: '工伤保险'
					},
					{
						type: '',
						label: '生育保险'
					},
					{
						type: '',
						label: '住房公积金'
					},
					{
						type: '',
						label: '周末双休'
					},
					{
						type: '',
						label: '公费培训'
					},
					{
						type: '',
						label: '带薪年假'
					},
					{
						type: '',
						label: '公费旅游'
					},
				],
				cardData: [

					{
						id: 1,
						src: require('../../assets/img/ad1.png'),
					},

					{
						id: 2,
						src: require('../../assets/img/ad1.png')
					},

					{
						id: 3,
						src: require('../../assets/img/ad1.png')
					},
					{
						id: 4,
						src: require('../../assets/img/ad1.png')
					},
					{
						id: 5,
						src: require('../../assets/img/ad1.png')
					},
				],
				companyinfo: {},
				defimgurl: global.baseimgurl,
				postdata: [],
				curr: 0,
				total: 0,
				PageNumber: 1,
				PageSize: 10,
				id: "",
				usertype: 0,
				userinfo: {
					peR_ID: ""
				},
				zphid: "",
				flay: true,
				iscss: false
			};
		},
		created() {
			this.id = this.$route.query.id;
			this.zphid = this.$route.query.zid;
			this.renderPage(this.$route.query.id, this.$route.query.zid)
		},
		methods: {
			favcomp() {
				var rdata = new Array();
				if (this.Required()) {
					return;
				}
				this.iscss = !this.iscss;
				if (!this.iscss) {
					var par = {
						id: this.companyinfo.coM_ID,
						uid: this.userinfo.peR_ID,
					};
					rdata = AddCall(par);
				} else {
					var par = {
						id: this.companyinfo.coM_ID,
						uid: this.userinfo.peR_ID,
					};
					rdata = CancelCall(par);
				}
				rdata.then((res) => {
					if (res.success) {
						this.$message({
							message: "操作成功",
							type: "success",
						});
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			Required() {
				var userinfo = null;
				let userinfoStr = localStorage.getItem("userinfo");
				if (userinfoStr != null && userinfoStr != '') {
					userinfo = JSON.parse(userinfoStr)
				}
				if (!userinfo || !userinfo.peR_ID) {
					this.resolvewin("/login", {
						type: 1,
						isback: 1
					}, 1);
					return false;
				} else {
					if (
						!userinfo ||
						!userinfo.peR_NAME ||
						!userinfo.peR_GENDER ||
						!userinfo.peR_BIRTHDAY ||
						!userinfo.peR_ADDRESS ||
						!userinfo.peR_CELLPHONE
					) {
						this.$confirm(
								"您还没有完善个人基础信息(姓名，性别，出生日期等等),赶快去完善吧！",
								"温馨提示", {
									confirmButtonText: "确定",
									cancelButtonText: "取消",
									type: "warning",
								}
							)
							.then(() => {
								this.resolvewin("/usercenter", {
									type: 1
								}, 1);
							})
							.catch(() => {});
						return true;
					} else {
						return false;
					}
				}
			},
			sharecomp() {},
			gojobPage(item) {
				this.resolvewin('/jobdetail', {
					id: item.oF_ID
				}, 2)
			},
			getsalary(item) {
				if (item.oF_SALARY_MY == 1) {
					return '面议'
				} else {
					return this.setsalary(item.oF_SALARY, item.oF_SALARYE)
				}
			},

			getPublishTime(time) {
				return time.substring(11, 16) + ' 发布';
			},
			//通过函数改变数据从而达到视图的改变
			renderPage(id, zphid) {
				this.id = id;
				this.zphid = zphid;
				if (!isnull(localStorage.getItem("userinfo"))) {
					this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
					this.usertype = this.userinfo.userType;
				}
				if (this.zphid) {
					this.jobfaircbycompanyid();
					this.onlineposicompanylist();
				} else {
					this.Getcompanydetails();
					this.Getcompanyposilists();
				}
			},
			toggleFun(p) {
				this.cardData = this.cardData.map((item, index, array) => {
					if (index === array.length - 1 && p === 1) {
						item = array[0]
					} else if (index === 0 && p === -1) {
						item = array[array.length - 1];
					} else {
						item = array[index + p];
					}
					return item;
				})
			},
			companyHomepage() {
				this.flay = !this.flay
			},
			movePosition() {
				this.flay = !this.flay
			},
			stringToDates(data) {
				return utils.stringToDate(data);
			},
			midname(data) {
				if (isnull(localStorage.getItem("token"))) {
					if (isnull(data)) {
						return data;
					}
					return utils.formatName(data);
				} else {
					return data;
				}
			},
			goto(url, data) {
				this.resolvewin(url, data, 1);
			},
			enter(i) {
				this.curr = i;
			},
			leave() {
				this.curr = null;
			},
			Getcompanydetails(id) {
				var _this = this;
				Getcompanydetail({
					id: this.id,
					uid: this.usertype == 1 ? this.userinfo.peR_ID : ""
				}).then((res) => {
					if (res.success) {
						_this.companyinfo = res.data.rows[0];
						if (!isnull(_this.companyinfo.isCall)) {
							_this.iscss = _this.companyinfo.isCall === 0 ? true : false;
						}
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			Getcompanyposilists(id) {
				var _this = this;
				Getcompanyposilist({
					id: this.id,
					PageSize: _this.PageSize,
					PageNumber: _this.PageNumber,
				}).then((res) => {
					if (res.success) {
						_this.postdata = res.data.rows;
						_this.total = res.data.total;
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			jobfaircbycompanyid() {
				var _this = this;
				jobfaircbycompanyid({
					id: this.zphid,
					cid: this.id
				}).then((res) => {
					if (res.success) {
						var data = res.data[0];
						_this.companyinfo = {
							coM_LOGO: data.coM_LOGO,
							coM_NAME: data.cComName,
							guimo: "",
							hangye: data.cHyName,
							leixing: data.cNatureName,
							coM_ZJLB_CODE: data.coM_ZJLB_CODE,
							coM_INTRODUCT: data.cIntroduce,
							coM_LINKMAN: data.cLxr,
							coM_MPHONE: data.cLxdh,
							coM_PHONE: "",
							coM_ADDRESS: data.cAddress,
						};
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			onlineposicompanylist() {
				var _this = this;
				var par = {
					id: this.id,
					uid: this.zphid,
					zid: this.usertype == 1 ? this.userinfo.peR_ID : "",
					PageSize: _this.PageSize,
					PageNumber: _this.PageNumber,
				};
				onlineposicompanylist(par).then((res) => {
					if (res.success) {
						res.data.rows.forEach((element) => {
							element.oF_POSI_NAME = element.zwmc;
							element.oF_POSI_NATURE = element.cJZ != 0 ? "紧缺工种" : "";
							element.oF_SALARY = element.cDyb;
							element.oF_SALARYE = element.cDye;
							element.oF_EDU_REQ = element.xueli;
							element.oF_SALARY_MY = element.cMy;
						});
						_this.total = res.data.total;
						_this.postdata = res.data.rows;
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			setcompanylogo(url) {
				return isnull(url) ?
					require("@/assets/img/logos.png") :
					this.defimgurl + url;
			},
			setsalary(a, b) {
				var data = [];
				if (!isnull(a)) {
					if (a < 100 && a > 0) {
						data.push(a + "k");
					} else if (a >= 1000) {
						data.push(a / 1000 + "k");
					} else {
						data.push(a);
					}
				}
				if (!isnull(b)) {
					if (b < 100 && b > 0) {
						data.push(b + "k");
					} else if (b >= 1000) {
						data.push(b / 1000 + "k");
					} else {
						data.push(b);
					}
				}
				if (data.length === 0) {
					return "面议";
				} else {
					return data.join("-");
				}
			},
			setflzl(item) {
				var data = item.split(",");
				data.forEach((element, i) => {
					if (!element) {
						data.splice(i, 1);
					}
				});
				if (data.length > 8) {
					data = data.splice(0, 8);
				}

				return data;
			},
			handleSizeChange(val) {
				this.PageSize = val;
				this.PageNumber = 1;
				if (this.zphid) {
					this.onlineposicompanylist();
				} else {
					this.Getcompanyposilists();
				}
			},
			handleCurrentChange(val) {
				this.PageNumber = val;
				if (this.zphid) {
					this.onlineposicompanylist();
				} else {
					this.Getcompanyposilists();
				}
			},
		},
		mounted() {},
	};
</script>
<style lang="scss" scoped>
	.company {
		width: 1200px;
		margin: 0px auto;
		padding: 16px 0;

		.company-header {
			// padding: 20px;
			background: #fff;
			border-radius: 4px;
			margin-bottom: 20px;

			.company-card {
				display: flex;
				align-items: center;
				padding: 20px;

				.company-logo {
					width: 90px;
					height: 90px;
					padding: 8px;

					// border: 1px solid #eee;
					// display: flex;
					// align-items: center;
					// justify-content: center;
					.el-image {
						width: 90px;
						height: 90px;
					}
				}

				.company-cell {
					text-align: left;
					flex: 1;
					width: 100%;
					padding-left: 24px;

					.company_profile {
						color: #898989;
						font-size: 14px;

						span {
							margin-right: 30px;

							i {
								padding-right: 5px;
							}
						}
					}

					.record {
						display: flex;
						flex: 1;
						margin-top: 15px;

						.position {
							width: 100px;
							height: 54px;
							border-right: 1px solid #EEE;
							text-align: center;
							font-size: 20px;
							padding-left: 30px;
							padding-right: 30px;
							color: rgba(51, 51, 51, 100);
						}

						.position1 {
							display: flex;
							align-items: center;
							padding-left: 30px;
							padding-right: 30px;
							height: 30px;
							font-size: 14px;
							text-align: center;
							color: rgb(0, 150, 255);

						}

						.position2 {
							display: flex;
							align-items: center;
							padding-right: 30px;
							border-right: 1px solid #EEE;
							height: 30px;
							font-size: 14px;
							text-align: center;
							color: rgb(0, 150, 255);

						}
					}

					.company-name {
						color: rgba(51, 51, 51, 100);
						font-size: 24px;
						font-weight: bold;
						margin-bottom: 20px;
					}

					.company-item {
						display: flex;
						align-items: center;
						margin-bottom: 10px;
						font-size: 14px;
						color: #898989;

						span {
							margin-right: 16px;
							padding-right: 16px;
							position: relative;
						}

						span::after {
							content: "";
							position: absolute;
							height: 100%;
							width: 1px;
							background: #eee;
							right: 0;
							top: 0;
						}

						i {
							width: 20px;
							height: 20px;
							font-size: 14px;
							color: #fff;
							background: #09d188;
							border-radius: 10px;
							text-align: center;
							display: flex;
							justify-content: center;
							align-items: center;
							margin-left: 12px;
						}
					}

					.company-item:last-child {
						margin-bottom: 0;
					}
				}

				.follow {
					width: 180px;
					height: 44px;
					background-color: rgba(0, 150, 255, 100);
					color: rgba(244, 248, 255, 100);
					font-size: 18px;
					text-align: center;
					font-family: Roboto;
					border: 1px solid rgba(0, 150, 255, 100);
					line-height: 44px;
					margin-bottom: 20px;
				}

				.share {
					color: rgba(137, 137, 137, 100);
					font-size: 14px;
					font-family: SourceHanSansSC-regular;

					img {
						width: 20px;
						height: 20px;
						margin: 0 auto;
					}
				}
			}

			.company-tab {
				width: 1200px;
				height: 70px;
				background: #f8f8f8;
				display: flex;

				.tab {
					width: 96px;
					height: 36px;
					background-color: rgba(0, 150, 255, 100);
					color: rgba(255, 255, 255, 100);
					font-size: 14px;
					text-align: center;
					font-family: Microsoft Yahei;
					line-height: 36px;
					margin: auto 0;
					margin-right: 40px;

				}

				.tab1 {
					width: 96px;
					height: 36px;
					text-decoration: none;
					// background-color: rgba(0, 150, 255, 100);
					color: #101010;
					font-size: 14px;
					text-align: center;
					font-family: Microsoft Yahei;
					line-height: 36px;
					margin: auto 0;
					margin-right: 40px;
					cursor: pointer;

				}

			}

			.homepage {
				width: 70%;
				background-color: #fff;
			}

			.center {
				display: flex;
				background-color: #f8f8f8;
			}

			.recent {
				width: 35%;
				margin-left: 2%;

				.recentBrowsing {
					width: 100%;
					// height: 455px;
					background-color: #fff;

					.title {
						color: rgba(16, 16, 16, 100);
						font-size: 16px;
						width: 97%;
						height: 50px;
						border-bottom: 1px solid #eee;
						margin-left: 3%;
						line-height: 50px;

						span {
							margin-left: 14px;
						}
					}

					.occupation {
						color: rgba(16, 16, 16, 100);
						font-size: 14px;
						width: 94%;

						// border-bottom: 1px solid #eee;
						margin-left: 3%;
						margin-right: 3%;
						// display: flex;
						// align-items: center;
						// justify-content: space-between;

						font-size: 14px;
						font-family: SourceHanSansSC-regular;
						padding-bottom: 30px;

						.text {
							margin-top: 30px;
						}


					}
				}

				.advertisement {
					width: 100%;
					height: 252px;
					background: #0096FF;
					margin-top: 16px;
				}

			}

			.company-detail {
				padding: 20px;

				.introduce-item {
					position: relative;

					.introduce-h2 {
						position: relative;
						font-size: 16px;
						color: #000;
						text-align: left;
						margin-bottom: 12px;
					}


					.cardBanner {

						padding: 10px 30px;

						background-color: #fff;

						// border: 1px solid #ccc;

						position: relative;

					}

					.cardBanner ul {

						display: flex;

						overflow: scroll;
						/*设置滚动条*/

					}

					.cardBanner ul::-webkit-scrollbar {
						/*隐藏滚动条*/

						display: none;

					}

					.cardBanner ul>li {
						//高能部分，flex不太好解释
						list-style-type: none;
						width: 31.33333%;

						flex-shrink: 0;

						padding-left: 3%;

						text-align: center;

					}

					.cardBanner ul>li:first-child {

						padding-left: 0;

					}

					.cardBanner ul>li a {

						display: block;

						width: 100%;

						height: 100%;

					}

					.cardBanner ul>li img {

						width: 100%;

						height: 170px;

						border-radius: 5px;

					}

					.cardBanner ul>li p {

						margin: 0;

					}

					[class^='arrow'] {

						font-size: 30px;

						transform: scaleX(.7);

						color: #ddd;

					}

					.arrow-left {

						position: absolute;

						left: 5px;

						top: 50%;

						margin-top: -17px;

					}

					.arrow-right {

						position: absolute;

						right: 5px;

						top: 50%;

						margin-top: -17px;

					}

					.address {
						width: 99.9%;
						height: 57px;
						border: 1px solid #eee;
						line-height: 57px;

						span {
							margin-right: 9px;
							color: rgba(137, 137, 137, 100);
							font-size: 14px
						}

					}

					.introduce-map {
						width: 100%;
						height: 300px;


					}

					.introduce-text {
						position: relative;
						text-align: left;
						font-size: 14px;
						color: #898989;
						display: flex;
						flex-direction: column;
						line-height: 1.7;

						h2 {
							font-size: 16px;
							font-weight: normal;
						}

						p {
							margin: 0px;
							padding: 0px;
							line-height: 26px;
						}

						.introduce-text-list {
							display: flex;
							align-items: center;
							margin-top: 12px;
							color: #666;

							span {
								display: block;
								width: 72px;
								font-size: 14px;
								color: #898989;
								text-align: right;
							}

							a {
								text-decoration: none;
								color: #ff7630;
								font-weight: bold;
							}
						}

						img {
							width: 100%;
						}
					}
				}

				.introduce-item:first-child {
					border-top: none;
				}
			}
		}

		.company-body {
			position: relative;
			background: #fff;

			.position-tab-title {
				line-height: 44px;
				background: #fff;
				height: 44px;
				display: flex;
				align-items: center;
				margin-bottom: 12px;
				margin-top: 10px;
				margin-bottom: 10px;
				position: relative;

				.text-title {
					font-size: 16px;
					color: #101010;

				}

				div {
					padding: 0px;
					margin: 0px 24px;
					font-size: 14px;
					position: relative;
					color: #898989;
				}

				.zhaizhaozw {
					font-size: 14px;
					color: #101010;
					font-family: SourceHanSansSC-regular;
					position: absolute;
					right: 0;
				}

				.active {
					color: #66b1ff;
				}

				.active::after {
					position: absolute;
					content: "";
					height: 3px;
					width: 100%;
					left: 0;
					bottom: 0;
					background: #66b1ff;
					border-radius: 2px;
				}
			}

			.job-title {
				padding-left: 24px;
				position: relative;
				font-size: 18px;
				font-weight: bold;
				color: #000;
				text-align: left;
				margin-bottom: 12px;
			}

			.job-title::before {
				width: 5px;
				left: 0;
				top: 2px;
				bottom: 2px;
				border-radius: 2.5px;
				background: #66b1ff;
				content: "";
				position: absolute;
			}

			.job-cell {
				width: 100%;

				.position-item {
					background: #fff;
					padding: 16px 24px;
					transition: all 0.5s;
					margin-bottom: 12px;
					display: flex;
					border: 1px solid #eee;
					width: 89%;
					margin-left: 2.5%;

					.left {
						width: 90%;

						.item-list {
							display: flex;
							align-items: center;
							margin-bottom: 16px;
							position: relative;
							cursor: pointer;

							.post-conpany {
								flex: 1;
								width: 100%;
								text-align: right;
								font-size: 18px;
								color: #333;
							}

							.post-num {
								flex: 1;
								width: 100%;
								text-align: right;
							}

							.post-name {
								font-size: 16px;
								// color: #66b1ff;
								color: #101010;
								margin-right: 16px;
								display: flex;

								.compName {
									min-width: 120px;
								}

								.salary {
									// color: #67c23a;
									color: #F68E08;
									margin: 0 15px;
								}
							}

							.post-data {
								font-size: 14px;
								color: #787878;
							}

							.post-salary {
								font-size: 14px;
								// color: rgba(255, 118, 48, 1);
								color: #898989;
								margin-right: 16px;
								max-width: 220px;
								overflow: hidden;
								line-height: 20px;
								height: 20px;
								text-overflow: ellipsis;
								white-space: normal;
							}

							.post-detail {
								font-size: 14px;
								color: #787878;
								width: 70%;

								div {
									margin-bottom: 10px;
								}
							}

							.post-num {
								font-size: 14px;
								color: #787878;

								b {
									color: #66b1ff;
								}
							}

							.post-welfare {
								span {
									padding: 4px 8px;
									font-size: 12px;
									color: #66b1ff;
									background: #eee;
									border-radius: 2px;
									margin-right: 12px;
								}
							}
						}

						.item-list:last-child {
							margin-bottom: 6px;
						}
					}

					.right {
						width: 100px;
						display: flex;
						flex-direction: column;
						align-items: flex-end;
						position: relative;

						.gx {

							font-size: 14px;
							// color: #666;
							color: #898989;
							margin-top: 20px;
						}

						.deliver-btn {
							flex: 1;
							width: 100%;
							text-align: right;
							transition: all 1s;
							position: absolute;
							bottom: 0;

							.el-button {
								padding: 8px 20px;
							}
						}
					}
				}
			}
		}
	}
</style>
